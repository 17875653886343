import {differenceInSeconds} from 'date-fns';
import {
    interval,
    of,
} from 'rxjs';
import {
    filter,
    map,
    takeWhile,
    tap,
} from 'rxjs/operators';

import type {Jwt} from '../jwt.model';
import type {TokenRefreshLock, TokenRenewal} from './token-refresh-lock.interface';

export class TokenRefreshLockLocalStorage implements TokenRefreshLock {

    /**
     * A token can be refreshing in this tab or another one.
     */
    private tokenRefreshingHere = false;
    private readonly TOKEN_REFRESHING = 'tokenRefreshing';

    constructor(
        private readonly getToken: () => Jwt | null,
    ) {
        window.addEventListener('unload', () => {
            if (this.tokenRefreshingHere) {
                this.setTokenRefreshingHere('cancelled');
            }
        });
    }

    refresh() {
        if (this.getTokenRefreshingInOtherTab()[0] !== 'none') {
            return interval(50).pipe(
                map(() => this.getTokenRefreshingInOtherTab()),
                tap(([status, since]) => {
                    if (status === 'renewing') { // hmm
                        if (since === undefined) {
                            return;
                        }

                        const diffInSeconds = differenceInSeconds(new Date(), since);
                        if (diffInSeconds > 10) {
                            throw new Error('Renewal in other tab timed out');
                        }
                    }
                }),
                takeWhile(([status]) => status === 'renewing', true),
                filter(([status]) => status !== 'renewing'),
                map(([status]) => {
                    const t = this.getToken();
                    if (t === null || status === 'cancelled') {
                        throw new Error('Token was not renewed in other tab.');
                    }

                    return t;
                }),
            );
        }

        this.setTokenRefreshingHere('renewing');

        return of('PerformRefresh' as const);
    }

    releaseLock(): void {
        this.setTokenRefreshingHere('none');
    }

    /**
     * It is possible that concurrent requests in multiple tabs each try to
     * renew the access token. In order to avoid lockout due to reusage of a
     * single use refresh token, the other tabs need to be made aware of an
     * ongoing token refresh.
     *
     * A flag ('renewing') is set in LocalStorage signaling a token renewal.
     * When the token finishes renewal, the flag is removed and this method will
     * return 'none'. If the tab is closed before finishing the request, the
     * token renewal is marked as cancelled and the user is signed out since
     * the API could already have spend the token.
     */
    private getTokenRefreshingInOtherTab(): [status: TokenRenewal, since?: Date] {
        let tokenRefreshing = localStorage.getItem(this.TOKEN_REFRESHING);
        if (tokenRefreshing === null) {
            return ['none'];
        }

        let since: Date | undefined;
        if (tokenRefreshing.includes(',')) {
            const [status, dateString] = tokenRefreshing.split(',');
            tokenRefreshing = status;
            since = new Date(Number(dateString));
        }

        switch (tokenRefreshing) {
            case 'cancelled':
                return ['cancelled', since];
            case 'renewing':
                if (since === undefined) {
                    // Old without date, clear it to fix browsers that are stuck in renewing
                    this.setTokenRefreshingHere('none');
                    return ['none'];
                }
                return ['renewing', since];
            default:
                // Unknown, get rid of it
                this.setTokenRefreshingHere('none');
                return ['none'];
        }
    }

    /**
     * Store a refreshing flag to signal other tabs to wait for token renewal
     * and not try to renew the token themselves.
     */
    private setTokenRefreshingHere(state: TokenRenewal): void {
        this.tokenRefreshingHere = state === 'renewing';
        if (state === 'none') {
            localStorage.removeItem(this.TOKEN_REFRESHING);
        } else if (state === 'renewing') {
            localStorage.setItem(this.TOKEN_REFRESHING, `${state},${new Date().getTime()}`);
        } else {
            localStorage.setItem(this.TOKEN_REFRESHING, state);
        }
    }
}
