import type {ParsingFunctionsObject} from 'apollo-link-scalars';
import {format, parseISO, startOfDay} from 'date-fns';

import {ExtendedError} from '../../error/extended.error';

function parseDate(value: string): Date {
    const date = parseISO(value);

    if (isNaN(date.getTime())) {
        throw new Error('Value cannot be parsed as date. Use the yyyy-mm-dd format.');
    }

    return date;
}

export const dateType: ParsingFunctionsObject = {
    parseValue(value?: unknown): Date | null {
        if (value == null) {
            return null;
        }

        if (typeof value === 'string') {
            return startOfDay(parseDate(value));
        }

        throw new ExtendedError('Could not parse value as Date', {value});
    },
    serialize(value?: unknown): string | null | undefined {
        if (value == null) {
            return value;
        }

        if (typeof value === 'string') {
            return value;
        }

        if (value instanceof Date) {
            return format(value, 'yyyy-MM-dd');
        }

        throw new ExtendedError('Could not serialize value as Date', {value});
    },
};
