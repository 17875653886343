<ng-container *transloco="let t; read: 'Methods.MagicLink'">
    <h1>{{ t('Title') }}</h1>
    <form (ngSubmit)="submit()" [formGroup]="form" *ngIf="!polling">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'Email' | transloco }}</mat-label>
            <input matInput
                   formControlName="email"
                   type="email"
                   placeholder="user@example.com"
                   autocomplete="username"
                   autofocus
                   required>
            <mat-error *ngFor="let e of errRepo.getErrors('email')">
                {{ e.message | transloco: e.error }}
            </mat-error>
        </mat-form-field>
        <p *ngIf="error" class="text-error">{{ error | transloco }}</p>
        <button type="submit" mat-flat-button color="primary">
            <app-submitting [submitting]="submitting"></app-submitting>
            {{ 'Login' | transloco }}
        </button>
    </form>
    <p *ngIf="polling">
        {{ t('Polling description') }}
    </p>
</ng-container>
