import {Logger} from './logger.util';
import {createPromise} from './promise.util';

/**
 * Requests a Web Lock and returns:
 * - lockAcquired:
 *   A promise that will resolve when the lock is acquired.
 *   The lock will be held until it is released using the releaseLock function.
 * - releaseLock:
 *   A function that can be called to release the lock.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
 */
export function requestWebLockPromise(
    lockName: string,
    lockOptions: LockOptions,
): [lockAcquired: Promise<Lock | null>, releaseLock: () => void] {
    // Typescript-ESLint should not be complaining:
    // https://github.com/typescript-eslint/typescript-eslint/issues/6547
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    const [holdLockPromise, releaseLock] = createPromise<void>();
    const [lockAcquiredPromise, resolveLockAcquired] = createPromise<Lock | null>();

    navigator.locks.request(lockName, lockOptions, async (lock) => {
        resolveLockAcquired(lock);
        await holdLockPromise;
    }).catch(error => {
        Logger.error({
            message: 'Promise returned by navigator.locks.request errored. This should not occur.',
            error,
            info: {
                lockName,
                lockOptions,
            },
        });
    });

    return [lockAcquiredPromise, releaseLock];
}
