import {from} from 'rxjs';
import {map} from 'rxjs/operators';

import {requestWebLockPromise} from '../../shared/utils/lock-api.util';
import type {Jwt} from '../jwt.model';
import type {TokenRefreshLock} from './token-refresh-lock.interface';

export class TokenRefreshLockWebLock implements TokenRefreshLock {

    private readonly lockName = 'tokenRefreshLock';
    private _releaseLock: (() => void) | undefined;

    constructor(
        private readonly getToken: () => Jwt | null,
    ) {
    }

    static isSupported(): boolean {
        return navigator.locks as any !== undefined;
    }

    refresh(oldToken: Jwt) {
        const [lockAcquired, releaseLock] = requestWebLockPromise(this.lockName, {});
        this._releaseLock = releaseLock;

        return from(lockAcquired).pipe(
            map(() => {
                // Token could have been refreshed in another tab while waiting for the lock
                const token = this.getToken();
                if (token === null || token.encodedForm === oldToken.encodedForm) {
                    return 'PerformRefresh';
                }

                return token;
            }),
        );
    }

    releaseLock(): void {
        this._releaseLock?.();
        this._releaseLock = undefined;
    }
}
