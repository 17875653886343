import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';

@Component({
    selector: 'app-submitting',
    templateUrl: './submitting.component.html',
    styleUrls: ['./submitting.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmittingComponent {

    @Input() submitting: boolean;
}
