import {inject} from '@angular/core';
import type {
    CanActivateFn,
} from '@angular/router';
import {
    Router,
} from '@angular/router';
import {
    map,
    take,
} from 'rxjs/operators';

import {AuthService} from '../auth.service';

export const AnonymousGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    return inject(AuthService).getUser().pipe(
        take(1),
        map(user => user !== null),
        map(authenticated => authenticated ? router.parseUrl('/dashboard') : true),
    );
};
