import {ErrorRepository} from 'angular-error-repository';

import {contractDurationCompatibilityError} from './contract-duration.error-message';
import {decimalError} from './decimal.error-message';
import {EmailError} from './email.error-message';
import {FqdnNumberError} from './fqdn.error-message';
import {IbanError} from './iban.error-message';
import {MinLengthError} from './min-length.error-message';
import {MinError} from './min.error-message';
import {RequiredError} from './required.error-message';
import {SelectItemError} from './select-item.error-message';
import {TelephoneNumberError} from './telephone-number.error-message';
import {urlError} from './url-https.error-message';

let initialized = false;

export function initErrorMessages() {
    if (initialized) {
        return;
    }

    initialized = true;

    Object.assign(ErrorRepository.errorMessages, {
        contractDurationCompatibility: contractDurationCompatibilityError,
        decimal: decimalError,
        email: EmailError,
        fqdn: FqdnNumberError,
        iban: IbanError,
        min: MinError,
        minlength: MinLengthError,
        required: RequiredError,
        selectItem: SelectItemError,
        telephoneNumber: TelephoneNumberError,
        url: urlError,
    });
}
