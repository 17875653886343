import type {ParsingFunctionsObject} from 'apollo-link-scalars';
import Decimal from 'bignumber.js';
import type {ConversionContext} from 'decoverto';
import {SimpleConverter} from 'decoverto';

import {ExtendedError} from '../../error/extended.error';

export function decimalOrPreserve(input: Decimal.Value): Decimal;
export function decimalOrPreserve(input: null): null;
export function decimalOrPreserve(input: undefined): undefined;
export function decimalOrPreserve(
    input: Decimal.Value | null | undefined,
): Decimal | null | undefined {
    if (input == null) {
        return input;
    }

    if (input === '') {
        return null;
    }

    return new Decimal(input);
}

function serializeDecimal(value?: unknown): string | null | undefined {
    if (value == null) {
        return value;
    }

    if (typeof value === 'string') {
        return value;
    }

    if (value instanceof Decimal) {
        return value.toJSON();
    }

    throw new ExtendedError('Could not serialize decimal', {value});
}

export class DecimalConverter extends SimpleConverter<Decimal, string | null | undefined> {

    constructor() {
        super(Decimal);
    }

    toInstance({source}: ConversionContext<any>): Decimal | null | undefined {
        if (source == null) {
            return source;
        }

        if (source instanceof Decimal) {
            return source;
        }

        return new Decimal(source);
    }

    toPlain({source}: ConversionContext<Decimal | null | undefined>): string | null | undefined {
        return serializeDecimal(source);
    }
}

export const decimalType: ParsingFunctionsObject = {
    parseValue(value: unknown): Decimal | null {
        if (value == null) {
            return null;
        } else if (typeof value === 'number'
            || typeof value === 'string'
            || value instanceof Decimal) {
            return new Decimal(value);
        }
        throw new ExtendedError('Could not parse value as Decimal', {value});
    },
    serialize: serializeDecimal,
};

export function nanToNull<T extends Decimal | number>(value: T): T | null {
    return isNaN(value as any) ? null : value;
}

export function assertNotNaN<T>(value: T): T {
    if (isNaN(value as any)) {
        throw new ExtendedError('Value is NaN', {value});
    }

    return value;
}
