import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {configureScope} from '@sentry/browser';
import {Decoverto} from 'decoverto';
import type {Observable} from 'rxjs';
import {
    BehaviorSubject,
    concat,
    concatMap,
    of,
} from 'rxjs';
import {
    catchError,
    finalize,
    map,
    share,
    tap,
} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {User} from '../dashboard/user/user.model';
import {graphqlErrorHandler} from '../grapqhl/graphql-error-handler';
import type {GraphqlFetchResult} from '../grapqhl/graphql.interface';
import {Logger} from '../shared/utils/logger.util';
import {Jwt} from './jwt.model';
import {TokenRefreshLockLocalStorage} from './token-refreshing/token-refresh-lock-local-storage';
import {TokenRefreshLockWebLock} from './token-refreshing/token-refresh-lock-web-lock';
import type {TokenRefreshLock} from './token-refreshing/token-refresh-lock.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private static readonly TOKEN = 'token';

    private tokenRefresher: Observable<Jwt> | null = null;
    private readonly tokenRefreshLock: TokenRefreshLock;
    private readonly user: BehaviorSubject<User | null>;
    private userRefresh: Observable<User | null> | null = null;
    private userIsInitialized = false;

    constructor(
        private readonly decoverto: Decoverto,
        private readonly http: HttpClient,
        private readonly router: Router,
    ) {
        this.user = new BehaviorSubject<User | null>(null);

        if (TokenRefreshLockWebLock.isSupported()) {
            this.tokenRefreshLock = new TokenRefreshLockWebLock(this.getToken.bind(this));
        } else {
            this.tokenRefreshLock = new TokenRefreshLockLocalStorage(this.getToken.bind(this));
        }
    }

    /**
     * Get the current token used for auth.
     */
    getToken(): Jwt | null {
        const encodedToken = localStorage.getItem(AuthService.TOKEN);

        if (encodedToken === null) {
            return null;
        }

        return Jwt.fromEncoded(encodedToken);
    }

    /**
     * Returns an observable which emits the authenticated user. If no user is
     * authenticated, null is returned. When a property of the authenticated is
     * updated, a new user object is emitted.
     */
    getUser(): Observable<User | null> {
        if (!this.userIsInitialized) {
            return this.refreshUser();
        }

        return this.user;
    }

    /**
     * Get a snapshot of the current user.
     */
    getUserSnapshot(): User | null {
        return this.user.getValue();
    }

    /**
     * Checks whether an OAuth token is present.
     */
    hasToken(): boolean {
        return this.getToken() !== null;
    }

    logIn(token: string): Observable<User | null> {
        this.setToken(token);
        return this.refreshUser();
    }

    /**
     * Sign out, update the currently authenticated user, and redirect to login.
     */
    logOut(): void {
        this.clearToken();
        // @todo logout API call
        this.nextUser(null);
        this.router.navigate(['/login']).catch(Logger.errorWrap);
    }

    /**
     * Emit a new user object which will replace the currently authenticated
     * user.
     */
    nextUser(user: User | null): void {
        configureScope((scope) => {
            scope.setUser(user === null
                ? null
                : {
                    email: user.email,
                    id: user.id,
                    username: user.fullName,
                });
        });
        this.userIsInitialized = true;
        this.user.next(user);
    }

    /**
     * Fetch a new access token using the refresh token. To be called when the
     * access token has expired. Returns the new token.
     */
    refreshToken(oldToken: Jwt): Observable<Jwt> {
        // If a token refresh is already in progress, return it. This prevents
        // multiple concurrent token refreshes
        if (this.tokenRefresher !== null) {
            return this.tokenRefresher;
        }

        this.tokenRefresher = this.tokenRefreshLock.refresh(oldToken).pipe(
            concatMap(value => {
                if (value === 'PerformRefresh') {
                    return this.http.post(
                        `${environment.api}/auth/refresh-jwt`,
                        null,
                        {
                            responseType: 'text',
                        },
                    ).pipe(
                        map(data => Jwt.fromEncoded(data)),
                    );
                }

                return of(value);
            }),
            tap(jwt => {
                this.setToken(jwt.encodedForm);
            }),
            finalize(() => {
                this.tokenRefresher = null;
                this.tokenRefreshLock.releaseLock();
            }),
            share(), // Prevent multiple subscription from causing multiple requests
        );

        return this.tokenRefresher;
    }

    /**
     * Refreshes the current user. This updates the AuthService's user
     * observable.
     * The returned observable keeps emitting the currently authenticated user.
     */
    refreshUser(): Observable<User | null> {
        // If a user refresh is already in progress, return it. This prevents
        // multiple concurrent refreshes
        if (this.userRefresh !== null) {
            return this.userRefresh;
        }

        if (!this.hasToken()) {
            this.nextUser(null);
            return this.user;
        }

        this.userRefresh = concat(
            this.http.post<GraphqlFetchResult>(`${environment.api}/graphql`, {
                    query: `{
                        me {
                            email
                            familyName
                            givenName
                            id
                            permissions
                        }
                    }`,
            }).pipe(
                finalize(() => {
                    this.userRefresh = null;
                }),
                map(response => ({
                    ...response,
                    data: response.data?.me ?? null,
                } as any)),
                map(graphqlErrorHandler),
                map(data => {
                    const user = this.decoverto.type(User).plainToInstance(data);

                    this.nextUser(user);
                    return user;
                }),
                // If any error occurs when the user is being fetched, the user is not authenticated
                // and null is emitted.
                catchError(err => {
                    Logger.handleRequestError({
                        error: err,
                        message: 'Error refreshing user',
                    });
                    return of(null);
                }),
                share(), // Prevent multiple subscription from causing multiple requests
            ),
            this.user, // First emit the refreshed user then continue emitting the user
        );

        return this.userRefresh;
    }

    private clearToken(): void {
        localStorage.removeItem(AuthService.TOKEN);
    }

    /**
     * Persists the token.
     */
    private setToken(token: string | null): void {
        if (token === null) {
            this.clearToken();
            return;
        }

        localStorage.setItem(AuthService.TOKEN, token);
    }
}
