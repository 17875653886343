import type {ErrorMessage} from 'angular-error-repository';

import type {DecimalValidatorErrors} from '../validator/decimal.validator';

export const decimalError: ErrorMessage<DecimalValidatorErrors> = info => {
    switch (info.error.code) {
        case 'maxDecimalPlaces':
            return `Error.Max decimal places ${info.error.max === 0 ? 'zero' : 'non-zero'}`;
        case 'parsing':
            return 'Error.Decimal.Cannot parse';
    }
};
