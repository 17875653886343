import {Injectable} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {TranslocoService} from '@ngneat/transloco';
import type {Locale} from 'date-fns';
import dateLocaleEn from 'date-fns/locale/en-GB';
import dateLocaleFr from 'date-fns/locale/fr';
import dateLocaleNl from 'date-fns/locale/nl-BE';

export const supportedLanguages = [
    'en',
    'nl',
    'fr',
] as const;

export type SupportedLanguages = typeof supportedLanguages[number];

const dateFnsLocales: {[k in SupportedLanguages]: Locale} = {
    en: dateLocaleEn,
    fr: dateLocaleFr,
    nl: dateLocaleNl,
};

export interface Language {
    id: SupportedLanguages;
    label: string;
}

export const languageNameMap = {
    nl: 'Nederlands',
    fr: 'Français',
    en: 'English',
} as const satisfies Record<SupportedLanguages, string>;

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    static readonly languages: Array<Language> = [
        {
            id: 'en',
            label: languageNameMap.en,
        },
        {
            id: 'fr',
            label: languageNameMap.fr,
        },
        {
            id: 'nl',
            label: languageNameMap.nl,
        },
    ];

    activeLanguage: Language;

    private initialized = false;

    constructor(
        private readonly dateAdapter: DateAdapter<any>,
        private readonly transloco: TranslocoService,
    ) {
    }

    getLanguages(): Array<Language> {
        return LanguageService.languages;
    }

    init(): void {
        if (this.initialized) {
            return;
        }

        this.activeLanguage = LanguageService.languages.find(l => {
            return l.id === this.transloco.getActiveLang();
        })!;

        if (!this.setLanguageById(localStorage.getItem('language'))) {
            this.detectLanguage();
        }

        this.initialized = true;
    }

    setLanguage(language: Language): void {
        const code = language.id;

        localStorage.setItem('language', language.id);
        this.transloco.setActiveLang(code);
        this.dateAdapter.setLocale(dateFnsLocales[code]);
        this.activeLanguage = language;
        // Force fetch language. This will aid with translations being used before the language is
        // loaded resulting in the missing handler firing. Race conditions still exists. If the
        // request fetching the language is slower than bootstrap it could still happen that no
        // language is loaded when a translation is attempted.
        this.transloco.load(code).subscribe();
    }

    /**
     * Returns `true` if the language was applied. `false` otherwise.
     * @param languageId
     */
    setLanguageById(languageId?: string | null): boolean {
        if (languageId == null) {
            return false;
        }

        const language = LanguageService.languages.find(l => l.id === languageId);

        if (language !== undefined) {
            this.setLanguage(language);
            return true;
        }

        return false;
    }

    private detectLanguage(): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const preferredLanguages = window.navigator.languages ?? [window.navigator.language];
        const preferences = preferredLanguages.map(l => {
            const hasCountry = l.indexOf('-');
            return hasCountry < 0 ? l : l.substring(0, hasCountry);
        });

        for (const preference of preferences) {
            const language = LanguageService.languages.find(l => l.id === preference);

            if (language !== undefined) {
                this.setLanguage(language);
                return;
            }
        }
    }
}
