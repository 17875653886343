import type {OnInit} from '@angular/core';
import {Component} from '@angular/core';

import {environment} from '../environments/environment';
import {initErrorMessages} from './error/messages/message-init';
import {LanguageService} from './i18n/language.service';
import {UpdateService} from './update/update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        private readonly languageService: LanguageService,
        private readonly updateService: UpdateService,
    ) {
    }

    ngOnInit(): void {
        this.languageService.init();
        initErrorMessages();

        if (environment.checkForUpdates) {
            this.updateService.startUpdateChecking();
        }
    }
}
