import {Any, array, model, property} from 'decoverto';

import type {Permission} from '../../auth/permissions.interface';
import {Permissions} from '../../auth/permissions.interface';
import {TeamUser} from '../team/team-user.model';

@model()
export class User {

    @property(() => String)
    division?: string | null;

    @property()
    email: string;

    @property()
    enabled: boolean;

    @property()
    familyName: string;

    @property()
    givenName: string;

    @property()
    id: string;

    @property(Any)
    permissions: Permissions = {};

    @property(array(() => TeamUser))
    teams: Array<TeamUser>;

    get fullName(): string {
        return `${this.givenName} ${this.familyName}`;
    }

    get initials(): string {
        return `${this.givenName.charAt(0)}${this.familyName.charAt(0)}`.toLocaleUpperCase();
    }

    hasAllPermissions(...permissions: Array<Permission>): boolean {
        return permissions.every(permission => this.hasPermission(permission));
    }

    hasAnyPermission(...permissions: Array<Permission>): boolean {
        return permissions.some(permission => this.hasPermission(permission));
    }

    hasPermission(permission: Permission): boolean {
        return this.permissions[permission] === true;
    }
}
