import {isPast} from 'date-fns';
import * as jwtDecode from 'jwt-decode';

import {ExtendedError} from '../error/extended.error';

export interface UserJwt {
    email: string;
    familyName: string;
    givenName: string;
    id: string;
    permissions: Permissions;
}

export class Jwt {

    /**
     * The client identifier of the OAuth 2.0 [RFC6749] client that requested the token.
     */
    client_id: string;

    /**
     * Encoded form of this JWT.
     */
    encodedForm: string;

    /**
     * Date at which the JWT will become invalid.
     */
    exp: Date;

    /**
     * Issued at.
     */
    iat: Date;

    /**
     * Token to refresh the JWT when it expires.
     */
    refreshToken: string;

    /**
     * Space separated list of claims.
     * e.g. files:read files:write user.profile.basic:read
     */
    scope: string;

    /**
     * Last time this JWT was updated.
     */
    updated_at: Date;

    user: UserJwt;

    constructor(data: Jwt | Record<string, any>) {
        this.client_id = data.client_id;
        this.encodedForm = data.encodedForm;
        this.exp = new Date(data.exp * 1000);
        this.iat = new Date(data.iat * 1000);
        this.refreshToken = data.refreshToken;
        this.scope = data.scope;
        this.updated_at = new Date(data.updated_at * 1000);
        this.user = data.user;
    }

    static fromEncoded(encoded: string): Jwt {
        try {
            const payload = jwtDecode<any>(encoded);
            payload.encodedForm = encoded;
            return new Jwt(payload);
        } catch (e) {
            throw new ExtendedError('JWT decoding error', {
                encoded: encoded,
                originalError: e,
            });
        }
    }

    isExpired(): boolean {
        return isPast(this.exp);
    }
}
