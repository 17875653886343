import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import type {TranslocoLoader} from '@ngneat/transloco';
import {TRANSLOCO_LOADER} from '@ngneat/transloco';
import {defer} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {Logger} from '../shared/utils/logger.util';

@Injectable({
    providedIn: 'root',
})
export class TranslocoHttpLoader implements TranslocoLoader {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    getTranslation(langPath: string) {
        const config: {
            extension: string;
            mapper: (content: string) => Promise<Record<string, any>> | Record<string, any>;
        } = environment.i18n.format === 'yaml'
            ? {
                extension: 'yaml',
                mapper: async content => {
                    const yaml = await import('js-yaml');
                    return yaml.safeLoad(content);
                },
            }
            : {
                extension: 'json',
                mapper: content => JSON.parse(content),
            };

        return this.http.get(
            `/assets/i18n/${langPath}.${config.extension}`,
            {
                responseType: 'text',
            },
        ).pipe(
            mergeMap(content => {
                return defer(async () => config.mapper(content));
            }),
            catchError(err => {
                Logger.handleRequestError({
                    error: err,
                    message: `Error fetching "${langPath}" translations`,
                });
                throw err;
            }),
        );
    }
}

export const translocoLoader = {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader};
