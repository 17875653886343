import type {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import type {Observable} from 'rxjs';
import {
    catchError,
    concatMap,
    map,
    tap,
} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import type {Jwt} from './jwt.model';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    private auth: AuthService;

    constructor(
        private readonly injector: Injector,
    ) {
        // Not using AuthService directly avoids a circular dependency
    }

    /**
     * Intercepts requests and attaches authorization tokens when required.
     *
     * When an access token is expired, the refresh token is used to get a new
     * access token. If the access token's renewal request fails the
     * AuthService.logOut() is called.
     *
     * It would also be possible to opt for executing the request regardless of
     * token refresh success and let the API deal with the absence of
     * authorization token. This, however, could cause unintended side effects
     * if the requested action is valid for both an authenticated user and an
     * unauthenticated user.
     *
     * Example: the response of a slug uniqueness check differs if the user is
     * authenticated since the user's current slug is reported as unique. So if
     * the request would be send after a failed token renewal the API would
     * unexpectedly report the user's slug as being nonunique.
     * The downside to this is that a request that can be made anonymously will
     * error when the token renewal fails.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.auth = this.injector.get(AuthService);
        const token = this.auth.getToken();

        // Don't send token if none is present or the endpoint is not owned
        if (!request.url.startsWith(environment.api) || token === null) {
            return next.handle(request);
        }

        if (token.isExpired() && request.url !== `${environment.api}/auth/refresh-jwt`) {
            return this.auth.refreshToken(token).pipe(
                map(newToken => this.attachToken(request, newToken)),
                // If the token cannot be refreshed, sign out
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400 || err.status === 401) {
                            this.auth.logOut();
                        }
                    }

                    throw err;
                }),
                concatMap((req: HttpRequest<any>) => this.handleAuthorizedRequests(req, next)),
            );
        }

        return this.handleAuthorizedRequests(this.attachToken(request, token), next);
    }

    private attachToken(request: HttpRequest<any>, token: Jwt): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token.encodedForm}`,
            },
        });
    }

    /**
     * Performs actions based on HTTP responses from authenticated requests.
     */
    private handleAuthorizedRequests(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse && this.hasStatusCode(event, 401)) {
                    this.auth.logOut();
                    throw new Error('User is not authenticated');
                }
            }),
        );
    }

    /**
     * Checks if the response has a certain status code.
     */
    private hasStatusCode(response: HttpResponse<any>, status: number): boolean {
        const errors: Array<any> = response.body?.errors ?? [];
        return errors.some(e => e.status === status);
    }
}
