<h1 mat-dialog-title>{{ 'Update.Dialog.Title' | transloco }}</h1>
<p mat-dialog-content>
    {{ 'Update.Dialog.Body' | transloco }}
</p>
<div mat-dialog-actions class="reverse-row">
    <button
        type="button"
        mat-button
        color="primary"
        [mat-dialog-close]="true"
        cdkFocusInitial>
        {{ 'Update.Dialog.Install update' | transloco }}
    </button>
    <button type="button" mat-button [mat-dialog-close]="false">
        {{ 'Update.Dialog.Delay update' | transloco:{minutes: 10} }}
    </button>
</div>
