import {Injectable} from '@angular/core';
import type {TranslocoMissingHandler} from '@ngneat/transloco';
import {
    TRANSLOCO_MISSING_HANDLER,
} from '@ngneat/transloco';
import type {TranslocoMissingHandlerData} from '@ngneat/transloco/lib/transloco-missing-handler';
import {captureEvent, Severity} from '@sentry/browser';

import {environment} from '../../environments/environment';

@Injectable()
export class CustomMissingHandler implements TranslocoMissingHandler {

    constructor() {
        window.missingKeys = {};
    }

    handle(key: string, info: TranslocoMissingHandlerData) {
        window.missingKeys![key] = key;
        // eslint-disable-next-line no-console
        console.log(`Missing translation: ${info.activeLang}.${key}`);
        captureEvent({
            fingerprint: [`missing-translation.${info.activeLang}.${key}`],
            level: Severity.Warning,
            message: `Missing translation for "${key}" language "${info.activeLang}"`,
        });

        return environment.i18n.prettifyMissingTranslations ? this.prettifyKey(key) : key;
    }

    /**
     * Try to make keys prettier in case missing translations get to production.
     * @param key
     */
    private prettifyKey(key: string): string {
        if (key.endsWith('.')) {
            return key;
        }

        const display = key.substring(key.lastIndexOf('.') + 1);
        return display.startsWith(' ') ? key : display;
    }
}

export const translocoCustomMissingHandler = {
    provide: TRANSLOCO_MISSING_HANDLER,
    useClass: CustomMissingHandler,
};
