import {APP_INITIALIZER, NgModule} from '@angular/core';
import type {ApolloClientOptions} from '@apollo/client/core';
import {from, InMemoryCache} from '@apollo/client/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {withScalars} from 'apollo-link-scalars';

import {GraphqlService} from './graphql.service';
import {dateType} from './types/date.type';
import {decimalType} from './types/decimal.type';

const typesMap = {
    Date: dateType,
    Decimal: decimalType,
};

export function createApollo(
    httpLink: HttpLink,
    graphqlService: GraphqlService,
): ApolloClientOptions<any> {
    const link = from([
        withScalars({
            schema: graphqlService.schema,
            typesMap,
        }),
        httpLink.create({uri: graphqlService.endpoint}),
    ]);

    return {
        link: link,
        cache: new InMemoryCache(),
        defaultOptions: {
            mutate: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
            query: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                errorPolicy: 'all',
                fetchPolicy: 'no-cache',
            },
        },
    };
}

@NgModule({
    imports: [
        ApolloModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (graphqlService: GraphqlService) => {
                return async () => graphqlService.fetchSchema();
            },
            deps: [GraphqlService],
            multi: true,
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                HttpLink,
                GraphqlService,
            ],
        },
    ],
})
export class GraphQLModule {
}
