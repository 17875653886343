import {NgModule} from '@angular/core';
import type {Routes} from '@angular/router';
import {RouterModule} from '@angular/router';

import {AnonymousGuard} from './auth/guards/anonymous.guard';
import {LoginComponent} from './auth/login/login.component';
import {MagicLinkSecretComponent} from './auth/magic-link/secret/magic-link-secret.component';
import {NotFoundComponent} from './error/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'dashboard',
        loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule,
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AnonymousGuard],
    },
    {
        path: 'magic-link/:secret',
        component: MagicLinkSecretComponent,
    },
    {
        path: 'signing',
        loadChildren: async () => (await import('./sign/sign.module')).SignModule,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
