import {Injectable} from '@angular/core';
import type {GraphQLSchema, IntrospectionQuery} from 'graphql';
import {buildClientSchema, getIntrospectionQuery} from 'graphql';

import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GraphqlService {
    readonly endpoint = `${environment.api}/graphql`;
    private _schema: GraphQLSchema;

    get schema(): GraphQLSchema {
        return this._schema;
    }

    async fetchSchema() {
        const body = JSON.stringify({
            query: getIntrospectionQuery({
                descriptions: false,
            }),
        });

        const response = await fetch(this.endpoint, {
            body,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
        });
        const introspection = await response.json() as {data: IntrospectionQuery};
        this._schema = buildClientSchema(introspection.data);
    }
}
