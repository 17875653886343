import type {ErrorMessage} from 'angular-error-repository';

import type {UrlValidatorError} from '../validator/url.validator';

export const urlError: ErrorMessage<UrlValidatorError> = (err) => {
    if ('allowedProtocols' in err.error) {
        return 'Error.URL.InvalidProtocol';
    }

    return 'Error.URL.Invalid';
};
