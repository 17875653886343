<ng-container *transloco="let t; read: 'Methods.MagicLink.Secret'">
    <ng-container *ngIf="fetching">
        <mat-spinner mode="indeterminate" aria-hidden="true"></mat-spinner>
        <p>{{ t('Fetching') }}</p>
    </ng-container>
    <ng-container *ngIf="success">
        <p>{{ t('Success') }}</p>
    </ng-container>
    <ng-container *ngIf="error">
        <p class="text-error">{{ error | transloco }}</p>
    </ng-container>
</ng-container>
