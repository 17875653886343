import {model, property} from 'decoverto';

@model()
export class TeamUserTeam {

    @property()
    id: string;

    @property()
    name: string;
}

@model()
export class TeamUserUser {

    @property()
    familyName: string;

    @property()
    givenName: string;

    @property()
    id: string;

    get fullName(): string {
        return `${this.givenName} ${this.familyName}`;
    }
}

@model()
export class TeamUser {

    @property()
    canEditAllOffers: boolean;

    @property()
    canViewAllOffers: boolean;

    @property(() => TeamUserTeam)
    team: TeamUserTeam;

    @property()
    teamId: string;

    @property(() => TeamUserUser)
    user: TeamUserUser;

    @property()
    userId: string;
}
