import type {ErrorMessage} from 'angular-error-repository';

export const MinLengthError: ErrorMessage<{
    actualLength: number;
    charactersShort: number;
    requiredLength: number;
}> = error => {
    const {actualLength, requiredLength} = error.error;
    error.error.charactersShort = requiredLength - actualLength;
    return `Error.Min length`;
};
