import type {OnInit} from '@angular/core';
import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Apollo, gql} from 'apollo-angular';
import {
    finalize,
    map,
} from 'rxjs/operators';

import {apolloGraphqlErrorHandlerSingle} from '../../../grapqhl/graphql-error-handler';
import {Logger} from '../../../shared/utils/logger.util';

@Component({
    selector: 'app-magic-link-secret',
    templateUrl: './magic-link-secret.component.html',
    styleUrls: ['./magic-link-secret.component.scss'],
})
export class MagicLinkSecretComponent implements OnInit {

    error: string | null = null;
    fetching = true;
    success = false;

    constructor(
        private readonly apollo: Apollo,
        private readonly route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.apollo.mutate({
            mutation: gql`
                mutation ($secret: String!) {
                    magicLinkLoginSecret(secret: $secret)
                }
            `,
            variables: {
                secret: this.route.snapshot.paramMap.get('secret')!,
            },
        }).pipe(
            map(data => apolloGraphqlErrorHandlerSingle<'Expired' | 'NotFound' | 'Success'>(data)),
            finalize(() => {
                this.fetching = false;
            }),
        ).subscribe(result => {
            if (result === 'Success') {
                this.success = true;
            } else {
                this.error = `Methods.MagicLink.Secret.${result}`;
            }
        }, error => {
            this.error = Logger.handleRequestError({
                error,
                message: 'Logging in with magic link secret failed',
            });
        });
    }

}
