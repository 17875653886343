import {Component, HostBinding} from '@angular/core';

@Component({
    selector: 'app-update-dialog',
    templateUrl: './update-dialog.component.html',
    styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent {

    @HostBinding('class.mat-typography') matTypography = true;
}
