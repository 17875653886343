<button
    type="button"
    mat-button
    [matMenuTriggerFor]="languageSelector"
    class="mat-button-leading-icon">
    <mat-icon>language</mat-icon>
    {{ languageService.activeLanguage.label }}
</button>
<mat-menu #languageSelector="matMenu">
    <button
        type="button"
        mat-menu-item
        *ngFor="let language of languageService.getLanguages()"
        (click)="languageService.setLanguage(language)">
        {{ language.label }}
    </button>
</mat-menu>
