import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslocoModule} from '@ngneat/transloco';

import {LanguageSelectorComponent} from '../i18n/language-selector/language-selector.component';
import {AddressInputComponent} from './address/address-input/address-input.component';
import {ArrayAdderRemoveDirective} from './array-adder/array-adder-remove.directive';
import {ArrayAdderComponent} from './array-adder/array-adder.component';
import {AutocompleteSelectComponent} from './autocomplete-select/autocomplete-select.component';
import {DownloadButtonComponent} from './file/download-button/download-button.component';
import {UploadImageComponent} from './file/upload-image/upload-image.component';
import {UploadImagesComponent} from './file/upload-images/upload-images.component';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {LogoComponent} from './logo/logo.component';
import {DecimalPipe} from './pipes/decimal.pipe';
import {LocalizeDatePipe} from './pipes/localize-date.pipe';
import {ReversePipe} from './pipes/reverse.pipe';
import {SanitizeHtmlPipe} from './pipes/sanitize-html.pipe';
import {SearchComponent} from './search/search.component';
import {StoredPageSizeDirective} from './stored-page-size.directive';
import {SubmittingComponent} from './submitting/submitting.component';
import {
    EditLanguageSwitcherComponent,
} from './translatable/edit-language-switcher/edit-language-switcher.component';
import {
    TranslatableFieldHintComponent,
} from './translatable/translatable-field-hint/translatable-field-hint.component';
import {TranslateTransatablePipe} from './translatable/translate-translatable.pipe';
import {YesNoDialogComponent} from './yes-no-dialog/yes-no-dialog.component';

@NgModule({
    declarations: [
        AddressInputComponent,
        ArrayAdderComponent,
        ArrayAdderRemoveDirective,
        AutocompleteSelectComponent,
        DecimalPipe,
        DownloadButtonComponent,
        EditLanguageSwitcherComponent,
        LanguageSelectorComponent,
        LoadingIndicatorComponent,
        LocalizeDatePipe,
        LogoComponent,
        ReversePipe,
        SanitizeHtmlPipe,
        SearchComponent,
        StoredPageSizeDirective,
        SubmittingComponent,
        TranslatableFieldHintComponent,
        TranslateTransatablePipe,
        UploadImageComponent,
        UploadImagesComponent,
        YesNoDialogComponent,
    ],
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslocoModule,
    ],
    exports: [
        AddressInputComponent,
        ArrayAdderComponent,
        ArrayAdderRemoveDirective,
        AutocompleteSelectComponent,
        DecimalPipe,
        DownloadButtonComponent,
        EditLanguageSwitcherComponent,
        LanguageSelectorComponent,
        LoadingIndicatorComponent,
        LocalizeDatePipe,
        LogoComponent,
        ReversePipe,
        SanitizeHtmlPipe,
        SearchComponent,
        StoredPageSizeDirective,
        SubmittingComponent,
        TranslatableFieldHintComponent,
        TranslatableFieldHintComponent,
        TranslateTransatablePipe,
        UploadImageComponent,
        UploadImagesComponent,
        YesNoDialogComponent,
    ],
})
export class SharedModule {
}
