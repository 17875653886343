type PromiseWithResolveReject<T> = [
    promise: Promise<T>,
    resolve: (value: PromiseLike<T> | T) => void,
    reject: () => void,
];

/**
 * Creates a promise and returns it together with its resolve and reject function.
 * Handy when a function needs to return a promise that it can later resolve/reject at its own
 * leisure but where the code that does the resolving and rejecting cannot be nested inside a
 * `new Promise`'s executor.
 */
export function createPromise<T>(): PromiseWithResolveReject<T> {
    let resolveFn: PromiseWithResolveReject<T>[1] | undefined;
    let rejectFn: PromiseWithResolveReject<T>[2] | undefined;
    const promise = new Promise<T>((resolve, reject) => {
        resolveFn = resolve;
        rejectFn = reject;
    });

    if (resolveFn === undefined || rejectFn === undefined) {
        // Just meant to circumvent:
        // TS2454: Variable resolveFn/rejectFn is used before being assigned.
        throw new Error('Promise functions are undefined, inconceivable!');
    }

    return [promise, resolveFn, rejectFn];
}
