import type {OnInit} from '@angular/core';
import {Component, DestroyRef, EventEmitter, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ErrorRepository} from 'angular-error-repository';
import {Apollo, gql} from 'apollo-angular';
import type {Observable} from 'rxjs';
import {
    interval,
} from 'rxjs';
import {
    catchError,
    concatMap,
    concatMapTo,
    filter,
    finalize,
    map,
    takeWhile,
    tap,
} from 'rxjs/operators';

import {
    apolloGraphqlErrorHandlerSingle,
    GraphqlErrors,
} from '../../grapqhl/graphql-error-handler';
import {isNotNull} from '../../shared/utils/guards.util';
import {Logger} from '../../shared/utils/logger.util';

@Component({
    selector: 'app-magic-link',
    templateUrl: './magic-link.component.html',
    styleUrls: ['./magic-link.component.scss'],
})
export class MagicLinkComponent implements OnInit {

    @Output() readonly loginSuccess = new EventEmitter<string>();
    @Output() readonly otherMethod = new EventEmitter();

    error: string | null = null;
    errRepo: ErrorRepository;
    form: UntypedFormGroup;
    polling = false;
    submitting = false;
    translationKey = 'Methods.MagicLink';

    constructor(
        private readonly apollo: Apollo,
        private readonly destroyedRef: DestroyRef,
    ) {
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            email: new UntypedFormControl(null, [
                Validators.required,
                Validators.email,
            ]),
        });
        this.errRepo = new ErrorRepository(this.form);
    }

    submit(): void {
        this.form.markAllAsTouched();

        if (!this.form.valid || this.submitting) {
            return;
        }

        this.error = null;
        this.submitting = true;
        const value = this.form.value;

        this.startMagicLink(value.email).pipe(
            takeUntilDestroyed(this.destroyedRef),
            tap(() => {
                this.polling = true;
            }),
            concatMap(token => interval(1000).pipe(
                concatMapTo(this.tryLoginMagicLink(token)),
                takeWhile(account => account === null, true),
                filter(isNotNull),
            )),
            finalize(() => {
                this.polling = false;
                this.submitting = false;
            }),
        ).subscribe(
            account => {
                this.loginSuccess.emit(account);
            },
            error => {
                if (error instanceof GraphqlErrors) {
                    if (error.hasErrorCode('UserIsDisabled')) {
                        this.error = 'User.Error.User is disabled';
                        return;
                    }
                    if (error.hasErrorCode('NotFound')) {
                        this.error = 'User.Error.User does not exist';
                        return;
                    }
                }

                this.error = Logger.handleRequestError({
                    error,
                    message: 'Error logging in with magic link',
                });
            },
        );
    }

    private startMagicLink(email: string): Observable<string> {
        return this.apollo.mutate({
            mutation: gql`
                mutation ($email: String!) {
                    requestMagicLinkLogin(email: $email)
                }
            `,
            variables: {
                email,
            },
        }).pipe(
            map(apolloGraphqlErrorHandlerSingle),
            catchError(err => {
                if (err instanceof GraphqlErrors) {
                    if (err.hasErrorCode('NotFound')) {
                        this.error = `${this.translationKey}.AccountNotFound`;
                    }
                }

                throw err;
            }),
        );
    }

    private tryLoginMagicLink(token: string): Observable<string | null> {
        return this.apollo.mutate({
            mutation: gql`
                mutation ($publicToken: String!) {
                    magicLinkLoginPublic(publicToken: $publicToken)
                }
            `,
            variables: {
                publicToken: token,
            },
        }).pipe(
            map(apolloGraphqlErrorHandlerSingle),
            catchError(err => {
                if (err instanceof GraphqlErrors) {
                    if (err.hasErrorCode('Expired')) {
                        this.error = `${this.translationKey}.Expired`;
                    }
                }

                throw err;
            }),
        );
    }
}
