import {Component} from '@angular/core';

import {LanguageService} from '../language.service';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {

    constructor(
        readonly languageService: LanguageService,
    ) {
    }
}
