import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import type {MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import type {TranslocoConfig} from '@ngneat/transloco';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_TRANSPILER,
    TranslocoModule,
} from '@ngneat/transloco';
import {MessageFormatTranspiler} from '@ngneat/transloco-messageformat';
import {Decoverto} from 'decoverto';
import {ToastrModule} from 'ngx-toastr';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {MagicLinkComponent} from './auth/magic-link/magic-link.component';
import {MagicLinkSecretComponent} from './auth/magic-link/secret/magic-link-secret.component';
import {TokenInterceptorService} from './auth/token-interceptor.service';
import {NotFoundComponent} from './error/not-found/not-found.component';
import {CustomErrorHandler} from './error/sentry/custom-error.handler';
import {GraphQLModule} from './grapqhl/graphql.module';
import {LanguageService} from './i18n/language.service';
import {translocoCustomMissingHandler} from './i18n/transloco-missing.handler';
import {translocoLoader} from './i18n/transloco.loader';
import {DateFnsDateAdapter, MAT_DATE_FNS_DATE_FORMATS} from './shared/date-fns';
import {SharedModule} from './shared/shared.module';
import {decoverto} from './shared/utils/decoverto.util';
import {UpdateDialogComponent} from './update/dialog/update-dialog.component';

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
    appearance: 'outline',
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MagicLinkComponent,
        MagicLinkSecretComponent,
        NotFoundComponent,
        UpdateDialogComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        GraphQLModule,
        HttpClientModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot({
            progressBar: true,
        }),
        TranslocoModule,
        AppRoutingModule, // Load routing last
    ],
    providers: [
        {provide: ErrorHandler, useClass: CustomErrorHandler},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions},
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                availableLangs: LanguageService.languages,
                defaultLang: 'nl',
                fallbackLang: 'en',
                flatten: {
                    aot: environment.production,
                },
                prodMode: environment.production,
                reRenderOnLangChange: true,
            } as TranslocoConfig,
        },
        {provide: TRANSLOCO_TRANSPILER, useClass: MessageFormatTranspiler},
        {provide: Decoverto, useValue: decoverto},
        translocoCustomMissingHandler,
        translocoLoader,
        {
            provide: DateAdapter,
            useClass: DateFnsDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_DATE_FNS_DATE_FORMATS,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
