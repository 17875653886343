import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationStart, Router} from '@angular/router';
import type {Observable} from 'rxjs';
import {EMPTY, merge, of, timer} from 'rxjs';
import {
    catchError,
    filter,
    map,
    mergeMap,
    throttleTime,
} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {UpdateDialogComponent} from './dialog/update-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    private enabled = false;
    private delayActive = false;

    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly dialog: MatDialog,
    ) {
    }

    startUpdateChecking(): void {
        if (this.enabled) {
            return;
        }

        merge(
            timer(30 * 60 * 1000),
            this.router.events.pipe(filter(event => event instanceof NavigationStart)),
        ).pipe(
            throttleTime(10000, undefined, {leading: true, trailing: true}),
            mergeMap(() => this.isUpdateAvailable().pipe(catchError(() => EMPTY))),
        ).subscribe(updateNeeded => {
            if (updateNeeded && !this.delayActive) {
                this.promptUpdate();
            }
        });

        this.enabled = true;
    }

    promptUpdate(): void {
        const dialogRef = this.dialog.open<
            UpdateDialogComponent,
            undefined,
            boolean
        >(UpdateDialogComponent);

        dialogRef.afterClosed().subscribe(shouldUpdate => {
            if (shouldUpdate === true) {
                document.location.reload();
            } else if (shouldUpdate === false) {
                this.delayActive = true;
                setTimeout(() => {
                    this.delayActive = false;
                }, 10 * 60 * 1000);
            }
        });
    }

    isUpdateAvailable(): Observable<boolean> {
        if (environment.release === undefined) {
            return of(false);
        }

        return this.http.get('/version.txt', {
            responseType: 'text',
        }).pipe(
            map(version => version.trim() !== environment.release?.trim()),
        );
    }
}
